







































































import { Component, Vue } from "vue-property-decorator";
import api from "@/api/index";
import {
  CustomFormType,
  ServiceManagementDto, ServiceManagementCreateOrUpdateDto
} from "@/api/appService";
import CustomFormDetailView from "@/components/CustomForm/FormDetail/index.vue";
import moment from "moment";
import CustomFormDataTableDetail from "@/components/CustomForm/TableDetail/index.vue";

@Component({
  name: "WorkOrderDetail",
  components: {
    CustomFormDetailView,
    CustomFormDataTableDetail
  }
})
export default class WorkOrderDetail extends Vue {
  detail: ServiceManagementDto = {
    service: {
      leaderUser: {
        surname: ""
      },
      type: {
        displayName: ""
      }
    }};
  loading = true;
  customFormId = 0;
  isFormReady = false;
  private id = 0;

  created() {
    this.id = parseInt(this.$route.params.id, 10);
    this.fetchData();
  }

  get leaderName() {
    if (this.detail && this.detail.service && this.detail.service.leaderUser) {
      return this.detail.service.leaderUser.surname!;
    }
    return "";
  }

  getTime(item: any) {
    return moment(item).format("HH:mm");
  }

  fetchData() {
    api.serviceManagement
      .get({ id: this.id })
      .then((result: ServiceManagementDto) => {
        this.detail = result;
        this.loading = false;
        this.fetchFormDetail();
      });
  }

  async fetchFormDetail() {
    const formId = await api.customFormService.getCustomFormId({
      hostType: CustomFormType.ServiceManagement,
      hostId: "301"
    });

    this.custogetmFormId = parseInt(formId, 10);
    this.isFormReady = true;
  }

  fetchFormData(): Promise<ServiceManagementCreateOrUpdateDto> {
    return api.serviceManagement.getForEdit({ id: this.id });
  }
}
